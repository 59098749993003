<template>
  <ion-menu class="side-menu" side="start" contentId="main-content">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t("Options") }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="ion-padding">
        <ion-item
          @click="goTo('/profile')"
          :class="{ active: isActive('/profile') }"
          detail="false"
        >
          <ion-icon :icon="personCircleOutline" slot="start"></ion-icon>
          {{ $t("My Profile") }}
        </ion-item>
        <ion-item
          @click="goTo('/vouchers')"
          :class="{ active: isActive('/vouchers') }"
          detail="false"
        >
          <ion-icon :icon="voucherIco" slot="start"></ion-icon>
          {{ $t("My Vouchers") }}
        </ion-item>
        <ion-item
          @click="goTo('/terms-and-conditions')"
          :class="{ active: isActive('/terms-and-conditions') }"
          detail="false"
        >
          <ion-icon :icon="clipboardOutline" slot="start"></ion-icon>
          {{ $t("Terms and conditions") }}
        </ion-item>
        <ion-item
          @click="goTo('/contact')"
          :class="{ active: isActive('/contact') }"
          detail="false"
        >
          <ion-icon :icon="mailUnreadOutline" slot="start"></ion-icon>
          {{ $t("Contact") }}
        </ion-item>
        <ion-item @click="logout" detail="false">
          <ion-icon :icon="closeCircleOutline" slot="start"></ion-icon>
          {{ $t("Logout") }}
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
  IonIcon,
  menuController
} from "@ionic/vue";
import {
  personCircleOutline,
  clipboardOutline,
  mailUnreadOutline,
  closeCircleOutline
} from "ionicons/icons";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import voucherIco from "@/assets/VoucherIco.svg";
export default {
  name: "SideMenu",
  components: {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonTitle,
    IonToolbar,
    IonIcon
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const goTo = path => {
      menuController.close("start");
      router.push(path);
    };
    const isActive = path => route.path.startsWith(path);
    const logout = () => {
      store.dispatch("auth/logout").then(() => {
        menuController.close("start");
        router.push({ path: "/welcome", replace: true });
      });
    };
    return {
      personCircleOutline,
      voucherIco,
      clipboardOutline,
      mailUnreadOutline,
      closeCircleOutline,
      logout,
      router,
      goTo,
      isActive
    };
  }
};
</script>

<style scoped>
ion-item.active,
ion-item.active ion-icon {
  color: var(--ion-color-primary);
}
</style>
