import axios from '@/plugins/axios';
import { DateTime } from "@/plugins/luxon";

const catchError = (e, commit) => {
  if (e.response && e.response.data && e.response.data.errors) {
    let errors = [];
    for (let error_type in e.response.data.errors) {
      e.response.data.errors[error_type].forEach((error) => {
        errors.push(error);
      });
    }
    if (!errors.length) {
      errors = ['There\'s been an error logging in'];
    }
    commit('SET_ERROR', errors);
  }
}

const checkLoadMonth = (params, state) => {
  const { year, month } = params;
  const { items } = state;
  const date = `${year.toString()}-${month.toString().padStart(2, 0)}`;

  return items.some((item) => item.date.includes(date));
};

export default {
  namespaced: true,
  state() {
    return {
      calendarSelectedDay: DateTime.now().startOf("day"),
      diarySelectedDay: DateTime.now().startOf("day"),
      loadItems: true,
      items: [],
      error: false
    };
  },
  mutations: {
    SET_ERROR(state, value) {
      if (value && !Array.isArray(value)) {
        value = [value];
      }
      state.error = value;
    },
    SET_ITEMS(state, value) {
      let array = [];

      value.forEach(element => {
        const index = state.items.findIndex((item) => item.date === element.date);
        if (index >= 0) {
          state.items[index].items = element.items;
        } else {
          array.push(element);
        }
      });
      state.items = [...state.items, ...array];
      // console.log(state.items);
    },
    SET_ITEMS_EMPTY(state) {
      state.items = [];
    },
    SET_CALENDAR_SELECTED_DAY(state, value) {
      state.calendarSelectedDay = DateTime.fromISO(value).startOf("day");
    },
    SET_DIARY_SELECTED_DAY(state, value) {
      state.diarySelectedDay = DateTime.fromISO(value).startOf("day");
    },
    SET_LOAD_ITEMS(state, value) {
      state.loadItems = value;
    },
    INSERT_ITEM(state, element) {
      var date = element.fecha_desde.substring(0, 10);
      const items = [...state.items];
      const index = items.findIndex((item) => item.date === date);
      items[index].items.push(element);
      items[index].items.sort(function (a, b) {
        if (b.tipo == 'nota') return -1;
        if (a.tipo == 'nota') return 1;
        return a.fecha_desde < b.fecha_desde ? -1 : 1;
      })
      state.items = items;
    },
    UPDATE_ITEM(state, element) {
      var date = element.fecha_desde.substring(0, 10);
      const items = [...state.items];
      const date_index = items.findIndex(item => item.date === date);
      const item_index = items[date_index].items.findIndex(item => item.id == element.id);
      if (item_index < 0) {
        items[date_index].items.push(element);
      } else {
        items[date_index].items[item_index] = element;
      }
      items[date_index].items.sort(function (a, b) {
        if (b.tipo == 'nota') return -1;
        if (a.tipo == 'nota') return 1;
        return a.fecha_desde < b.fecha_desde ? -1 : 1;
      });
      state.items = items;
    },
    DELETE_ITEM(state, element) {
      var date = element.fecha_desde.substring(0, 10);
      const index = state.items.findIndex((item) => item.date === date);
      const prevItems = state.items.filter((item) => item.date !== date);
      const items = state.items[index].items.filter((item) => item.id !== element.id);
      state.items = [
        ...prevItems,
        { date, items }
      ];
    }
  },
  actions: {
    clearItems({ commit }) {
      commit('SET_ITEMS_EMPTY');
    },
    async getItems({ commit, state, rootState }, params) {
      commit('SET_ERROR', false);
      if (!checkLoadMonth(params, state)) {
        commit('SET_LOAD_ITEMS', true);
      }
      try {
        const { data } = await axios.get(`/calendario/${rootState.auth.activeDependant.id}/items`, {
          params: {
            ...params
          }
        });
        commit('SET_ITEMS', data.items);
        commit('SET_LOAD_ITEMS', false);
      } catch (e) {
        console.log(e);
        catchError(e, commit);
      }
    },
    async createItem({ commit, rootState }, formData) {
      commit('SET_ERROR', false);
      try {
        const { data: newItem } = await axios.post(`/calendario/${rootState.auth.activeDependant.id}/items`, formData);
        commit('INSERT_ITEM', newItem);
      } catch (e) {
        catchError(e, commit);
      }
    },
    async editItem({ commit, getters, rootState }, formData) {
      commit('SET_ERROR', false);
      try {
        const { id } = formData;
        const { data: newItem } = await axios.put(`/calendario/${rootState.auth.activeDependant.id}/items/${id}`, formData);
        const original_item = getters.getItemById(id);
        if (original_item.fecha_desde.substring(0, 10) === formData.fecha_desde.substring(0, 10)) {
          commit('UPDATE_ITEM', newItem);
        } else {
          commit('DELETE_ITEM', original_item);
          commit('INSERT_ITEM', newItem);
        }
      } catch (e) {
        console.log(e);
        catchError(e, commit);
      }
    },
    async setCompletedTask({ commit, rootState }, formData) {
      commit('SET_ERROR', false);
      try {
        const { id } = formData;
        const { data: newItem } = await axios.put(`/calendario/${rootState.auth.activeDependant.id}/items/${id}/tarea-completada`, formData);
        commit('UPDATE_ITEM', newItem);
      } catch (e) {
        console.log(e);
        catchError(e, commit);
      }
    },
    changeCalendarSelectedDay({ commit }, day) {
      commit('SET_CALENDAR_SELECTED_DAY', day);
    },
    changeDiarySelectedDay({ commit }, day) {
      commit('SET_DIARY_SELECTED_DAY', day);
    },
    setLoadItems({ commit }, value) {
      commit('SET_LOAD_ITEMS', value);
    },
    async deleteItem({ commit, rootState }, item) {
      try {
        await axios.delete(`/calendario/${rootState.auth.activeDependant.id}/items/${item.id}`);
        commit('DELETE_ITEM', item);
        return true;
      } catch (e) {
        catchError(e, commit);
        return false;
      }
    }
  },
  getters: {
    diarySelectedDay: state => {
      return state.diarySelectedDay;
    },
    diarySelectedDayString: state => {
      return state.diarySelectedDay.toFormat("yyyy-MM-dd");
    },
    calendarSelectedDay: state => {
      return state.calendarSelectedDay;
    },
    calendarSelectedDayString: state => {
      return state.calendarSelectedDay.toFormat("yyyy-MM-dd");
    },
    getItemById: state => (id) => state.items.reduce((found, day) => {
      if (found) {
        return found;
      }
      const items = day.items.filter(item => item.id === id);
      if (items.length) {
        return items[0];
      }
    }, false),
    getItemsSelectedDay: (state, getters) => {
      let itemCalendar = state.items.find(d => d.date === getters.diarySelectedDayString);
      return itemCalendar !== undefined ? itemCalendar.items : [];
    }
  }

};
