import axios from '@/plugins/axios';
import config from '@/config';

const catchError = (e, commit) => {
  commit('SET_LOGGED', false);
  commit('SET_USER', false);
  commit('SET_TOKEN', null);
  if (e.response && e.response.data && e.response.data.errors) {
    let errors = [];
    for (let error_type in e.response.data.errors) {
      e.response.data.errors[error_type].forEach((error) => {
        errors.push(error);
      });
    }
    if (!errors.length) {
      errors = ['There\'s been an error logging in'];
    }
    commit('SET_ERROR', errors);
  }
}

export default {
  namespaced: true,
  state() {
    let user = false;
    if (!navigator.onLine) {
      user = window.localStorage.getItem("user");
      if (user) user = JSON.parse(user);
    }

    return {
      logged: false,
      user: user,
      activeDependant: false,
      error: false,
      token: window.localStorage.getItem("token"),
    }
  },
  mutations: {
    SET_LOGGED(state, logged) {
      state.logged = logged;
    },
    SET_USER(state, user) {
      let activeDependant = false;
      if (!user) {
        window.localStorage.removeItem('user');
      } else {
        if (!user.cuidados) {
          user.fulfilled = false;
          user.fulfillStage = 'FulfillUserType';
        } else {
          user.fulfilled = true;
          if (user.cuidados == 'beneficiario' && !user.direcciones.length) {
            user.fulfilled = false;
            user.fulfillStage = 'FulfillUserAddress';
          }
          if (user.cuidados == 'cuidador' && !user.beneficiarios.length) {
            user.fulfilled = false;
            user.fulfillStage = 'FulfillUserInvite';
          }
        }
        if (user.fulfilled) {
          /* FAKE Beneficiarios */
          user.beneficiarios = [
            {
              id: 2,
              nombre: "Emilia",
              apellidos: "Salort",
              avatar: config.url_media + "/user/emilia.jpg"
            },
            {
              id: 3,
              nombre: "Francisco",
              apellidos: "Navarro",
              avatar: config.url_media + "/user/francisco.jpg"
            },
            {
              id: 4,
              nombre: "Jacinto",
              apellidos: "Martinez",
              avatar: config.url_media + "/user/jacinto.jpg"
            },
            {
              id: 5,
              nombre: "Josefa",
              apellidos: "Beltrán",
              avatar: config.url_media + "/user/josefa.jpg"
            }
          ];
          if (user.cuidados == 'beneficiario') {
            activeDependant = user;
          } else {
            activeDependant = user.beneficiarios[0];
          }
        }
        window.localStorage.setItem('user', JSON.stringify(user));
      }
      state.activeDependant = activeDependant;
      state.user = user;
    },
    SET_ACTIVEDEPENDANT(state, activeDependant) {
      if (state.user.cuidados == 'dependiente') {
        return state.activeDependant = state.user;
      }
      if (state.user.beneficiarios.includes(activeDependant)) {
        state.activeDependant = activeDependant;
      }
    },
    SET_ERROR(state, value) {
      if (value && !Array.isArray(value)) {
        value = [value];
      }
      state.error = value;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      if (!token) {
        window.localStorage.removeItem('token');
      } else {
        window.localStorage.setItem('token', token);
      }
    },
  },
  actions: {
    async login({ dispatch, commit }, credentials) {
      commit('SET_ERROR', false);
      try {
        const { data } = await axios.post('/login', credentials);
        commit('SET_TOKEN', data);
        await dispatch('getUser');
      } catch (e) {
        catchError(e, commit);
      }
    },
    async register({ dispatch, commit }, formData) {
      commit('SET_ERROR', false);
      try {
        const { data } = await axios.post('/register', formData);
        commit('SET_TOKEN', data);
        await dispatch('getUser');
      } catch (e) {
        catchError(e, commit);
      }
    },
    async token({ dispatch, commit }, lt) {
      commit('SET_ERROR', false);
      try {
        const { data } = await axios.post('/token', { lt: lt, device_name: 'app' });
        commit('SET_TOKEN', data);
        await dispatch('getUser');
        return true;
      } catch (e) {
        commit('SET_LOGGED', false);
        commit('SET_USER', null);
        commit('SET_TOKEN', null);
        return false;
      }
    },
    async logout({ state, commit }) {
      if (state.token) {
        await axios.post('/logout');
      }
      commit('SET_LOGGED', false);
      commit('SET_USER', null);
      commit('SET_TOKEN', null);
    },
    async getUser({ commit }) {
      try {
        const { data } = await axios.get('/user');
        if (data) {
          commit('SET_LOGGED', true);
          commit('SET_USER', data);
          return;
        }
      } catch (e) {
        console.log('error loggeando');
      }
      commit('SET_LOGGED', false);
      commit('SET_USER', null);
      commit('SET_TOKEN', null);
    },
    setUser({ commit }, data) {
      commit('SET_USER', data);
    },
    async addAddress({ commit, state }, address) {
      try {
        const request = await axios.post('/user-fulfill', { address: address });
        if (request.status == 200) {
          const user = { ...state.user };
          user.direcciones = [address];
          commit('SET_USER', user);
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    async sendApplication({ commit, state }, email) {
      commit('SET_ERROR', false);
      const emailLowered = email.toLowerCase();
      if (state.user.email.toLowerCase() == emailLowered) {
        commit('SET_ERROR', "You can't invite yourself");
        return false;
      }
      if (state.user.solicitudes.length && state.user.solicitudes.some((req) => req.email.toLowerCase() == emailLowered)) {
        commit('SET_ERROR', "Already requested");
        return false;
      }
      if (state.user.cuidados == 'cuidador' && state.user.beneficiarios.length && state.user.beneficiarios.some((req) => req.email.toLowerCase() == emailLowered)) {
        commit('SET_ERROR', "Already requested");
        return false;
      }
      if (state.user.cuidados == 'beneficiario' && state.user.cuidadores.length && state.user.cuidadores.some((req) => req.email.toLowerCase() == emailLowered)) {
        commit('SET_ERROR', "Already requested");
        return false;
      }
      try {
        const request = await axios.post('/send-application', { email: email });
        if (request.status == 200 || request.status == 201) {
          const user = { ...state.user };
          user.solicitudes.push(request.data);
          commit('SET_USER', user);
          return true;
        }
        return false;
      } catch (e) {
        if (e.response && e.response.data && e.response.data.errors) {
          commit('SET_ERROR', Object.values(e.response.data.errors).flat());
        }
        return false;
      }
    },
    async discardApplication({ commit, state }, email) {
      commit('SET_ERROR', false);
      try {
        const request = await axios.post('/discard-application', { email: email });
        if (request.status == 200) {
          const user = { ...state.user };
          user.solicitudes = user.solicitudes.filter((solicitud) => solicitud.email.toLowerCase() != email.toLowerCase());
          commit('SET_USER', user);
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    setActiveDependant({ commit }, dependant) {
      commit('SET_ACTIVEDEPENDANT', dependant);
    },
    clearError({ commit }) {
      commit('SET_ERROR', false);
    }
  }
};
