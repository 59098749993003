import { createStore } from 'vuex';
import auth from './auth';
import calendar from './calendar';
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    calendar
  }
});
