import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/store';

const routes = [
  {
    path: '/',
    redirect: '/1hand',
  },
  {
    path: '/1hand',
    name: 'Main',
    component: () => import('@/views/Main.vue'),
    meta: { requiresAuth: true, requiresCompleteUser: true },
    children: [
      {
        path: '',
        redirect: '/diary',
      },
      {
        path: '/diary',
        name: 'Diary',
        component: () => import('@/views/main/Diary.vue'),
        meta: { requiresAuth: true, requiresCompleteUser: true },
      },
      {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('@/views/main/Calendar.vue'),
        meta: { requiresAuth: true, requiresCompleteUser: true },
      },
      {
        path: '/services',
        name: 'Services',
        component: () => import('@/views/main/Services.vue'),
        meta: { requiresAuth: true, requiresCompleteUser: true },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/options/Profile.vue'),
        meta: { requiresAuth: true, requiresCompleteUser: true },
      },
      {
        path: '/vouchers',
        name: 'Vouchers',
        component: () => import('@/views/options/Vouchers.vue'),
        meta: { requiresAuth: true, requiresCompleteUser: true },
      },
      {
        path: '/terms-and-conditions',
        name: 'TermsAndConditions',
        component: () => import('@/views/options/TermsAndConditions.vue'),
        meta: { requiresAuth: true, requiresCompleteUser: true },
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/options/Contact.vue'),
        meta: { requiresAuth: true, requiresCompleteUser: true },
      }
    ],
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('@/views/Welcome.vue'),
    meta: { hideFromLogged: true },
  },
  {
    path: '/auth-selector',
    name: 'AuthSelector',
    component: () => import('@/views/auth/Selector.vue'),
    meta: { hideFromLogged: true },
  },
  {
    path: '/auth-local',
    name: 'LocalUser',
    component: () => import('@/views/auth/LocalUser.vue'),
    meta: { hideFromLogged: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
    meta: { hideFromLogged: true },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: { hideFromLogged: true },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/auth/Logout.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/auth/Register.vue'),
    meta: { hideFromLogged: true },
  },
  {
    path: '/fulfill-user-type',
    name: 'FulfillUserType',
    component: () => import('@/views/auth/FulfillUserType.vue'),
    meta: { requiresAuth: true, isFulfilling: true },
  },
  {
    path: '/fulfill-user-address',
    name: 'FulfillUserAddress',
    component: () => import('@/views/auth/FulfillUserAddress.vue'),
    meta: { requiresAuth: true, isFulfilling: true },
  },
  {
    path: '/fulfill-user-invite',
    name: 'FulfillUserInvite',
    component: () => import('@/views/auth/FulfillUserInvite.vue'),
    meta: { requiresAuth: true, isFulfilling: true },
  },
  {
    path: '/diary-item-detail/:date/:id',
    name: 'DiaryItemDetail',
    component: () => import('@/views/main/CalendarItemDetail.vue'),
    meta: { requiresAuth: true, requiresCompleteUser: true },
  },
  {
    path: '/calendar-item-detail/:date/:id',
    name: 'CalendarItemDetail',
    component: () => import('@/views/main/CalendarItemDetail.vue'),
    meta: { requiresAuth: true, requiresCompleteUser: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/clearError');
  if (store.state.auth.token && !store.state.auth.user) {
    await store.dispatch('auth/getUser');
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.logged) {
      next({ name: 'Welcome' });
      return;
    }
  }
  if (to.matched.some(record => record.meta.requiresCompleteUser) && !store.state.auth.user.fulfilled) {
    if (to.name !== store.state.auth.user.fulfillStage) {
      next({ name: store.state.auth.user.fulfillStage, replace: true });
      return;
    }
  }
  if (to.matched.some(record => record.meta.isFulfilling)) {
    if (store.state.auth.user.fulfilled) {
      next({ name: 'Diary', replace: true });
      return;
    }
    if (store.state.auth.user.fulfillStage != to.name) {
      next({ name: store.state.auth.user.fulfillStage, replace: true });
      return;
    }
  }
  if (to.matched.some(record => record.meta.hideFromLogged)) {
    if (store.state.auth.logged) {
      next({ name: 'Diary' });
      return;
    }
  }
  next();
});

export default router;
