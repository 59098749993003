import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue';

import App from '@/App.vue'
import i18n from '@/lang';
import store from './store'
import router from '@/router';
import '@/plugins/axios';
import VCalendar from 'v-calendar';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
// import './theme/variables.css';
// import './theme/main.css';
import './theme/custom.scss';

/* Capaitor Plugins para Deeplinking */
// import { Plugins } from '@capacitor/core';
// const { CapApp } = Plugins;

// CapApp.addListener('appUrlOpen', (data) => {
//   const slug = data.url.split('app.cmb.coodex.net').pop();
//   if (!slug) return;
//   router.push({
//     path: slug,
//   });
// });

const app = createApp(App)
  .use(VCalendar, {})
  .use(i18n)
  .use(store)
  .use(IonicVue)
  .use(router);

router.isReady().then(() => {
  app.mount('#app');
});
