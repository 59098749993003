<template>
  <ion-app>
    <side-menu />
    <ion-router-outlet id="main-content" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, getPlatforms } from "@ionic/vue";
import { defineComponent, onMounted } from "vue";
import SideMenu from "@/components/SideMenu.vue";
import { ScreenOrientation } from "@ionic-native/screen-orientation/";
import { Plugins } from "@capacitor/core";
const { SplashScreen } = Plugins;
export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    SideMenu
  },
  setup() {
    onMounted(() => {
      if (
        getPlatforms().includes("capitor") ||
        getPlatforms().includes("cordova")
      ) {
        ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
        SplashScreen.hide();
      }
    });
  }
});
</script>
