import { createI18n } from 'vue-i18n'

import en from './en.json';
import es from './es.json';

import config from '@/config';

const messages = {
  en,
  es,
};

const i18n = createI18n({
  // legacy: false,
  locale: config.locale || 'es',
  fallbackLocale: config.fallbackLocale || 'en',
  messages,
});

export default i18n;
