import axios from 'axios';
import config from '@/config';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = config.url_back;
axios.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('token');
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export default axios;
